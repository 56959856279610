import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, CircularProgress } from '@mui/material';
import api from '../authMiddleware';

function DashboardPage() {
  const [user, setUser] = useState({ username: '', email: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch user profile from the API
  

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Dashboard
      </Typography>

      <Paper sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
          This Page Show Chart and Progreses for all negotiates
        </Paper>
    </Box>
  );
}

export default DashboardPage;
