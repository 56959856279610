// src/pages/WizardPage.js

import React, { useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, Paper, Container } from '@mui/material';
import EventDetailsForm from './wizard/EventDetailsForm';
import PurchaseRulesForm from './wizard/PurchaseRulesForm';
import EmailGenerationRulesForm from './wizard/EmailGenerationRulesForm';
import CoordinateForm from './wizard/CoordinateForm';
import ConfirmationForm from './wizard/ConfirmationForm';

function WizardPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    eventDetails: {},
    purchaseRules: {},
    emailGenerationRules: {},
    coordinate: {},
  });

  const steps = [
    'Project Details',
    'Counterparty details',
    'Additional information ',
    'Email Generation Rules',
    'Confirmation'
  ];

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleDataChange = (stepData) => {
    setFormData((prevData) => ({ ...prevData, ...stepData }));
  };

  const handleSubmit = () => {
    console.log("Final data:", formData);
    setActiveStep(activeStep + 1);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" color="primary" gutterBottom>AI Coordinator</Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper sx={{ padding: 3, marginTop: 3 }}>
        {activeStep === 0 && (
          <EventDetailsForm
            data={formData.eventDetails}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 1 && (
          <CoordinateForm
            data={formData.coordinate}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 2 && (
          <PurchaseRulesForm
            eventDescription={formData.eventDetails.description}
            supply={formData.coordinate.supply}
            data={formData.purchaseRules}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 3 && (
          <EmailGenerationRulesForm
            data={formData.emailGenerationRules}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 4 && (
          <ConfirmationForm
            data={formData}
            onConfirm={handleSubmit}
          />
        )}
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        {activeStep === steps.length - 1 ? (
          <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
        )}
      </Box>
    </Container>
  );
}

export default WizardPage;
