// src/components/NavDrawer.js

import React from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar, Typography, AppBar, Box, IconButton, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const NavDrawer = ({ children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6">Feba</Typography>
      </Toolbar>
      <List style={{ flex: 1 }}>
      <ListItem button component={Link} to="/dashboard" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <AnalyticsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/wizard" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <AutoAwesomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Let’s start" />
        </ListItem>
        <ListItem button component={Link} to="/events" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <EventIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="My projects" />
        </ListItem>
        <ListItem button component={Link} to="/coordinates" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <LocationOnIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Project’s process" />
        </ListItem>
        <ListItem button component={Link} to="/purchase-rules" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <ShoppingCartIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Skills of AI Coordinator" />
        </ListItem>
        <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <AccountCircleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </ListItem>
      </List>
      <ListItem
        style={{
          marginTop: 'auto',
          backgroundColor: '#f5f5f5', // Optional: Add a subtle background for better distinction
          borderTop: '1px solid #ddd', // Optional: Add a border to separate from the main list
        }}
        aria-label=""
      >
        <ListItemIcon>
          <HelpIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Version 0.0.1" />
      </ListItem>
    </div>
  );
  

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'primary.main',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Feba Control Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        {/* Desktop Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: 'background.paper',
              borderRight: '1px solid #e0e0e0',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: '#f5f5f5',
          minHeight: '100vh',
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default NavDrawer;
