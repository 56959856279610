import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Paper, IconButton, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../authMiddleware';

function PurchaseRulesPage() {
  const [purchaseRules, setPurchaseRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch purchase rules from the API
  useEffect(() => {
    const fetchPurchaseRules = async () => {
      try {
        const response = await api.get('/coordinate/purchase-rules/');
        setPurchaseRules(response.data);
      } catch (err) {
        setError('Failed to load purchase rules');
      } finally {
        setLoading(false);
      }
    };
    fetchPurchaseRules();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom color="primary">Skills of AI Coordinator</Typography>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {!loading && !error && (
        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Skills</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseRules.map((rule, index) => (
                <TableRow key={index} hover>
                  <TableCell>{rule.rule}</TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default PurchaseRulesPage;
