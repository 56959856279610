import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Button, CircularProgress, TextField, Snackbar, Alert } from '@mui/material';
import api from '../../authMiddleware';

function ConfirmationForm({ data }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state

  const [purchaseRules, setPurchaseRules] = useState({});
  const [emailRules, setEmailRules] = useState({});
  const [combinedPurchaseRules, setCombinedPurchaseRules] = useState([]);
  const [combinedEmailRules, setCombinedEmailRules] = useState([]);

  const [manualPurchaseRules, setManualPurchaseRules] = useState(['']);
  const [manualEmailRules, setManualEmailRules] = useState(['']);

  useEffect(() => {
    const storedPurchaseRules = JSON.parse(localStorage.getItem('purchaseRules')) || {};
    const storedEmailRules = JSON.parse(localStorage.getItem('emailRules')) || {};
    setPurchaseRules(storedPurchaseRules);
    setEmailRules(storedEmailRules);

    const purchaseQuestions = Object.values(storedPurchaseRules).map(item => item.question);
    const purchaseAnswers = Object.values(storedPurchaseRules).map(item => item.answer);
    combinePurchaseRules(purchaseQuestions, purchaseAnswers);

    const emailQuestions = Object.values(storedEmailRules).map(item => item.question);
    const emailAnswers = Object.values(storedEmailRules).map(item => item.answer);
    combineEmailRules(emailQuestions, emailAnswers);
  }, []);

  const combinePurchaseRules = async (questions, answers) => {
    try {
      const response = await api.post('/agents/coordinate/combine/', { questions, answers });
      if (response.data && Array.isArray(response.data.rules)) {
        setCombinedPurchaseRules(response.data.rules);
      }
    } catch {
      setError('Failed to combine purchase rules.');
    }
  };

  const combineEmailRules = async (questions, answers) => {
    try {
      const response = await api.post('/agents/email/combine/', { questions, answers });
      if (response.data && Array.isArray(response.data.rules)) {
        setCombinedEmailRules(response.data.rules);
      }
    } catch {
      setError('Failed to combine email generation rules.');
    }
  };

  const addManualPurchaseRule = () => setManualPurchaseRules([...manualPurchaseRules, '']);
  const addManualEmailRule = () => setManualEmailRules([...manualEmailRules, '']);

  const handleManualPurchaseRuleChange = (index, value) => {
    const updatedRules = [...manualPurchaseRules];
    updatedRules[index] = value;
    setManualPurchaseRules(updatedRules);
  };

  const handleManualEmailRuleChange = (index, value) => {
    const updatedRules = [...manualEmailRules];
    updatedRules[index] = value;
    setManualEmailRules(updatedRules);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const eventResponse = await api.post('/coordinate/events/create/', {
        name: data.eventDetails.name,
        description: data.eventDetails.description,
      });
      const eventId = eventResponse.data.id;

      const emailRuleIds = [];
      for (const rule of combinedEmailRules) {
        const emailRuleResponse = await api.post('/coordinate/email-generation-rules/create/', { rule });
        emailRuleIds.push(emailRuleResponse.data.id);
      }

      for (const rule of manualEmailRules.filter(rule => rule.trim() !== '')) {
        const emailRuleResponse = await api.post('/coordinate/email-generation-rules/create/', { rule });
        emailRuleIds.push(emailRuleResponse.data.id);
      }

      const purchaseRuleIds = [];
      for (const rule of combinedPurchaseRules) {
        const purchaseRuleResponse = await api.post('/coordinate/purchase-rules/create/', { rule });
        purchaseRuleIds.push(purchaseRuleResponse.data.id);
      }

      for (const rule of manualPurchaseRules.filter(rule => rule.trim() !== '')) {
        const purchaseRuleResponse = await api.post('/coordinate/purchase-rules/create/', { rule });
        purchaseRuleIds.push(purchaseRuleResponse.data.id);
      }

      await api.post('/coordinate/coordinates/create/', {
        name: data.coordinate.name,
        event: eventId,
        purchase_rules: purchaseRuleIds,
        email_generation_rules: emailRuleIds,
        supply: data.coordinate.supply,
        supplier_email: data.coordinate.supplierEmail,
      });

      setSuccess(true);
      setSnackbarOpen(true); // Show success message
    } catch (err) {
      setError('An error occurred while submitting the data.');
      setSnackbarOpen(true); // Show error message
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close the Snackbar
  };

  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh - 100px)',
          overflowY: 'auto',
          padding: 2,
          border: '1px solid #ddd',
          borderRadius: 2,
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography variant="h6">Project Details</Typography>
        <Typography><strong>Project Name:</strong> {data.eventDetails.name}</Typography>
        <Typography><strong>Project Description:</strong> {data.eventDetails.description}</Typography>

        <Typography variant="h6" sx={{ mt: 3 }}>Additional information </Typography>
        {combinedPurchaseRules.map((rule, index) => (
          <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography>{rule}</Typography>
          </Paper>
        ))}

        <Typography variant="h6" sx={{ mt: 3 }}>Skills of AI Coordinator</Typography>
        {manualPurchaseRules.map((rule, index) => (
          <TextField
            key={index}
            label={`Negotiate Rule ${index + 1}`}
            value={rule}
            onChange={(e) => handleManualPurchaseRuleChange(index, e.target.value)}
            fullWidth
          />
        ))}
        <Button variant="contained" color="primary" onClick={addManualPurchaseRule}>+ Add Negotiate Rule</Button>

        <Typography variant="h6" sx={{ mt: 3 }}>Email Generation Rules</Typography>
        {combinedEmailRules.map((rule, index) => (
          <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography>{rule}</Typography>
          </Paper>
        ))}

        <Typography variant="h6" sx={{ mt: 3 }}>Add an Email Generation Rules</Typography>
        {manualEmailRules.map((rule, index) => (
          <TextField
            key={index}
            label={`Email Rule ${index + 1}`}
            value={rule}
            onChange={(e) => handleManualEmailRuleChange(index, e.target.value)}
            fullWidth
          />
        ))}
        <Button variant="contained" color="primary" onClick={addManualEmailRule}>+ Add Email Rule</Button>
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'CONFIRM'}
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={success ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {success ? 'Submission successful!' : error || 'An error occurred.'}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ConfirmationForm;
