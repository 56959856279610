// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavDrawer from './components/NavDrawer';
import ProfilePage from './pages/ProfilePage';
import CoordinatesPage from './pages/CoordinatesPage';
import EventsPage from './pages/EventsPage';
import PurchaseRulesPage from './pages/PurchaseRulesPage';
import EmailGenerationRulesPage from './pages/EmailGenerationRulesPage';
import WizardPage from './pages/WizardPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import DashboardPage from './pages/DashboardPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Login route outside NavDrawer for initial access */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        {/* Protected routes within NavDrawer */}
        <Route
          path="/*"
          element={
            <NavDrawer>
              <Routes>
                <Route path="/" element={<ProfilePage />} />
                <Route path="/coordinates" element={<CoordinatesPage />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/purchase-rules" element={<PurchaseRulesPage />} />
                <Route path="/email-generation-rules" element={<EmailGenerationRulesPage />} />
                <Route path="/wizard" element={<WizardPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
              </Routes>
            </NavDrawer>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
