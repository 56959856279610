import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, CircularProgress } from '@mui/material';
import api from '../authMiddleware';

function ProfilePage() {
  const [user, setUser] = useState({ username: '', email: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch user profile from the API
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/account/profile/');
        setUser(response.data);
      } catch (err) {
        setError('Failed to load profile');
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Profile
      </Typography>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {!loading && !error && (
        <Paper sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            Username: {user.username}
          </Typography>
          <Typography variant="h6">
            Email: {user.email}
          </Typography>
        </Paper>
      )}
    </Box>
  );
}

export default ProfilePage;
