// src/pages/wizard/CoordinateForm.js

import React from 'react';
import { TextField, Box } from '@mui/material';

function CoordinateForm({ data, onDataChange }) {
  const handleChange = (e) => {
    onDataChange({ coordinate: { ...data, [e.target.name]: e.target.value } });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Counterparty Name"
        name="name"
        value={data.name || ''}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Counterparty request"
        name="supply"
        value={data.supply || ''}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Counterparty Email"
        name="supplierEmail"
        type="email"
        value={data.supplierEmail || ''}
        onChange={handleChange}
        fullWidth
        required
      />
    </Box>
  );
}

export default CoordinateForm;
