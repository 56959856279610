// src/pages/wizard/EventDetailsForm.js

import React from 'react';
import { TextField, Box } from '@mui/material';

function EventDetailsForm({ data, onDataChange }) {
  const handleChange = (e) => {
    onDataChange({ eventDetails: { ...data, [e.target.name]: e.target.value } });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Projects name"
        name="name"
        value={data.name || ''}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="Project description"
        name="description"
        value={data.description || ''}
        onChange={handleChange}
        fullWidth
        multiline
        rows={4}
        required
      />
    </Box>
  );
}

export default EventDetailsForm;
