// src/pages/wizard/PurchaseRulesForm.js

import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import api from '../../authMiddleware';
function PurchaseRulesForm({ eventDescription, supply, data, onDataChange }) {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (eventDescription && supply) {
      fetchQuestions();
    }
  }, [eventDescription, supply]);

  const fetchQuestions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await api.post('/agents/coordinate/rules-questions/', {
        event_description: eventDescription,
        supply,
      });
      
      const { questions } = response.data;
      if (questions && questions.length > 0) {
        setQuestions(questions);
      } else {
        setError('No questions found.');
      }
    } catch (err) {
      setError('Failed to fetch questions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setAnswer('');
    }
  };

  const handleAnswerChange = (e) => {
    const newAnswer = e.target.value;
    setAnswer(newAnswer);

    // Store answer in the form data
    const updatedData = { ...data.purchaseRules, [`question_${currentQuestionIndex}`]: newAnswer };
    onDataChange({ purchaseRules: updatedData });

    // Store question and answer in local storage
    const storedData = JSON.parse(localStorage.getItem('purchaseRules')) || {};
    storedData[`question_${currentQuestionIndex}`] = { question: questions[currentQuestionIndex], answer: newAnswer };
    localStorage.setItem('purchaseRules', JSON.stringify(storedData));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          {questions.length > 0 && (
            <>
              <Typography variant="h6">{questions[currentQuestionIndex]}</Typography>
              <TextField
                label="Your Answer"
                value={answer}
                onChange={handleAnswerChange}
                fullWidth
                multiline
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNextQuestion}
                  disabled={currentQuestionIndex >= questions.length - 1}
                >
                  Another
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default PurchaseRulesForm;
